<template>

    <section class="sectionAreas ">

      <v-container fluid class="pa-0">
        <div class="paddingLeft ">
        <v-row class="align-center">

              <v-col cols="12" class="pb-0 pt-0">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" >{{ $t('Discover the heritage')}}</div>
            </v-col>
            <v-col cols="12" v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >
                       <div class="mb-4">{{ $t('area-subtxt')}} </div>
                        <div class="mb-4">{{ $t('area-subtxt01')}} </div>
                          <div class="mb-4">{{ $t('area-subtxt02')}} </div>
            </v-col>
            
        </v-row>
        </div>
        <div class="paddingAll 1">
           <v-row class="align-center ">
 <v-col  cols="12" class="py-2 px-2">
            <img class="about-img"  src="@/assets/area1.png" alt="img" />
          </v-col>
              <v-col cols="12" class="pb-0 pt-0 ">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" >{{ $t('area-txt1')}} </div>
            </v-col>
            <v-col cols="12" v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >
                       <div class="mb-3">{{ $t('area-subtxt1')}} </div>
                                   </v-col>
           
        </v-row>
        </div>
        <section class="conOfsection23">
           <div class="pattern">
       </div>
          <div class="paddingAll pink-bg 2">
               <v-row class="align-center pos-relative">
  <v-col  cols="12" class="py-2 px-2">
            <img class="about-img"  src="@/assets/area2.png" alt="img" />
          </v-col>
              <v-col cols="12" class="pb-0 pt-0 ">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" >{{ $t('area-txt2')}}</div>
            </v-col>
            <v-col cols="12" v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >
                       <div class="mb-3">{{ $t('area-subtxt2')}}</div>
                                   </v-col>
          
        </v-row>
        </div>
        <div class="paddingAll 3">
              <v-row class="align-center ">
   <v-col  cols="12" class="py-2 px-2">
            <img class="about-img"  src="@/assets/area3.png" alt="img" />
          </v-col>
              <v-col cols="12" class="pb-0 pt-0 ">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" >{{ $t('area-txt3')}} </div>
            </v-col>
            <v-col cols="12" v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >
                       <div class="mb-3">{{ $t('area-subtxt3')}}</div>
                                   </v-col>
           
        </v-row>
        </div>
        </section>
        <div class="paddingAll pink-bg 4">
            <v-row class="align-center">
  <v-col  cols="12" class="py-2 px-2">
            <img class="about-img"  src="@/assets/area4.png" alt="img" />
          </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'" >{{ $t('area-txt4')}}</div>
            </v-col>
            <v-col cols="12" v-bind:class="$i18n.locale === 'ar' ?' areaTxtAr' : 'areaTxtEn'" >
                       <div class="mb-3">{{ $t('area-subtxt4')}}</div>
                                   </v-col>
             <v-col  cols="12" class="py-2 px-2">
          </v-col>
        </v-row>
        </div>
      </v-container>
    </section>

</template>
<script>
export default {
  name:'about',

mounted(){
   setTimeout(() => {
    var el = this.$el.getElementsByClassName(this.$route.query.id)[0];
  el.scrollIntoView({ behavior: 'smooth' });
  console.log(this.$route.query.id); })

}

}

</script>
<style lang="scss" scoped>
.sectionAreas{
    padding: 4% 0%;
    padding-bottom: 0%;
}
.conOfsection23{
  position: relative;
}
.pattern{
      background: url(/img/pattern.9433a9ed.png);
    background-position: left;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    opacity: 0.5;
    height: 100%;
}
.about-img{
    width: 100%;
}
.paddingAll{
  padding: 4%;
}
.paddingLeft{
  padding: 0% 4%;
}

</style>
